// Variables
$background-color: rgba(255, 255, 255, 0.8); // White with 80% opacity
$spinner-size: 50px;
$spinner-color: #9c7d5c; // Blue color for the spinner

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $background-color;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; // Ensures the loader is above everything else
}

.loaderContent {
  display: flex;
  flex-direction: column; // Ensure the logo and spinner are stacked vertically
  align-items: center;    // Center align the logo and spinner
}

.loaderLogo {
  max-width: 150px;
  height: 50px;
  margin-bottom: 40px; // Spacing between the logo and spinner
}

.spinner {
  font-size: $spinner-size;
  color: $spinner-color;
  animation: spin 1s linear infinite;
}
.loaderText{
  font-size: 22px;
}
// Spinner animation
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
